<template>
  <div v-if="dataLoaded" class="all-wrap">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      // apiUrl: "http://159f-gallery.com/panel/api/",
      dataLoaded: false,
    }
  },
  created() {},
  mounted() {
    // if (process.env.VUE_APP_API_URL != undefined)
    //   this.apiUrl = process.env.VUE_APP_API_URL

    this.dataLoaded = true

    // axios.get(this.apiUrl + "all").then((response) => {
    //   let data = response.data
    //   data.params.lang = "en"
    //   this.$store.dispatch("setExhibitions", data.exhibitions)
    //   this.$store.dispatch("setFairs", data.fairs)
    //   this.$store.dispatch("setArtists", data.artists)
    //   this.$store.dispatch("setParams", data.params)
    //   this.dataLoaded = true
    // })
  },
  computed: {
    // params() {
    // return this.$store.getters.getParams()
    // },
  },
  methods: {},
}
</script>
