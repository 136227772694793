import { createRouter, createWebHistory } from "vue-router"

import store from "../store/index"

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
    meta: { transition: "pages-fade" },
  },
]

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior: (to, from, savedPosition) => {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else if (to.hash) {
  //     return {
  //       selector: to.hash,
  //     }
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // },
})

router.beforeEach(function (to, from, next) {
  if (to.meta.transition != "none")
    // console.log(to.meta.transition != "none")
    store.dispatch("setPagesTransition", true)

  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)

  setTimeout(() => {
    store.dispatch("setPagesTransition", false)
  }, 352.5)

  next()
})

router.afterEach(() => {})

export default router
