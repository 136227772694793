import { createStore } from "vuex"

export default createStore({
  state: {
    params: {
      address: "",
      tgUrl: "",
      lang: "en",
    },
    menuCategories: [
      { id: 1, title: "Пицца" },
      { id: 2, title: "Завтраки" },
      { id: 3, title: "Пиво" },
    ],
    menuItems: [
      { id: 1, category: 1, title: "Вителло", weight: 340, price: 540 },
      { id: 2, category: 1, title: "Буррата", weight: 360, price: 560 },
      { id: 3, category: 1, title: "Марэ", weight: 370, price: 590 },
      { id: 4, category: 1, title: "Мартаделла", weight: 350, price: 540 },
      { id: 5, category: 2, title: "Утренний бургер", weight: 320, price: 520 },
      { id: 6, category: 2, title: "Венские вафли", weight: 280, price: 490 },
      { id: 7, category: 2, title: "Бекон/пашот", weight: 330, price: 520 },
      { id: 8, category: 2, title: "Бенедикт", weight: 350, price: 560 },
      { id: 9, category: 2, title: "Бодрый бекон", weight: 370, price: 540 },
      { id: 10, category: 2, title: "Сырный бриошь", weight: 320, price: 520 },
    ],
    pagesTransition: false,
  },
  getters: {
    getMenuCategories: (state) => () => {
      return state.menuCategories
    },
    getMenuItems: (state) => (category) => {
      if (category == 0) return state.menuItems
      else
        return state.menuItems.filter((item) => {
          return item.category == category
        })
    },
    getMenuItem: (state) => (id) => {
      return state.menuItems.find((item) => {
        return item.id == id
      })
    },
    getParams: (state) => () => {
      return state.params
    },
    getPagesTransition: (state) => () => {
      return state.pagesTransition
    },
  },
  mutations: {
    SET_MENU_CATEGORIES(state, c) {
      state.menuCategories = c
    },
    SET_PARAMS(state, p) {
      state.params = p
    },
    SET_TRANSITION(state, val) {
      state.pagesTransition = val
    },
  },
  actions: {
    setMenuCategories({ commit }, newValue) {
      commit("SET_MENU_CATEGORIES", newValue)
    },
    setParams({ commit }, newValue) {
      commit("SET_PARAMS", newValue)
    },
    setPagesTransition({ commit }, newValue) {
      commit("SET_TRANSITION", newValue)
    },
  },
  modules: {},
})
